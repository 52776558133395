import OurProcessSectionItem from './OurProcessSectionItem';
import './ourProcessSection.scss';
import { images } from '../../configs';

const OurProcessSection = () => {
  const {process1,process2,process3,process4} = images
  const ourProcessItems = [
    {
      order: '1.',
      title: 'Explore and Assess',
      icon: process1,
      textItems: [
        {
          item: 'Begin with a discovery phase to gather information and evaluate the current state.',
        },
        {
          item: 'Evaluate the gathered information to pinpoint opportunities and potential solutions.',
        },
      ],
    },
    {
      order: '2.',
      title: 'Understand and Examine',
      icon: process2,
      textItems: [
        { item: "Analyze the needs of the client's business and project thoroughly." },
        {
          item: 'Identify patterns and trends in the requirements to formulate hypotheses and solutions.',
        },
      ],
    },
    {
      order: '3.',
      title: 'Designing and Prototyping',
      icon: process3,
      textItems: [
        { item: 'Crafting the solution architecture to meet project requirements.' },
        {
          item: 'Developing a prototype to showcase the functionality and features of the solution.',
        },
      ],
    },
    {
      order: '4.',
      title: 'Build and Operate',
      icon: process4,
      textItems: [
        { item: "Develop solutions tailored to the client's precise specifications." },
        {
          item: 'Leverage expertise and experience to ensure the delivery of top-notch solutions.',
        },
        { item: 'Provide ongoing support and maintenance after implementation' },
      ],
    },
  ];

  return (
    <section className='our_process_section'>
      <h3>Our Process</h3>
      <div className='our_process_content'>
        {ourProcessItems.map(item => {
          return (
            <OurProcessSectionItem
              key={item.title}
              order={item.order}
              title={item.title}
              icon={item.icon}
              textItems={item.textItems}
            />
          );
        })}
      </div>
    </section>
  );
};

export default OurProcessSection;
