import Carousel from './Carousel';
import Heading from './Heading';
import './carouselSection.scss'
import { FC } from 'react';


const CarouselSection:FC<{getElementId:any}> = ({getElementId}) => {
  return (
    <section className='carousel'>
      <Carousel/>
      <Heading getElementId={getElementId}/>
    </section>
  );
};

export default CarouselSection
