import { FC } from 'react';
import './requestModal.scss';
import { images } from '../../configs';

const RequestModal: FC<{ modalState: string, exitModal: () => void }> = ({ modalState, exitModal }) => {
  const {received, sadcloud} = images
  const copyEmail = () => {
    navigator.clipboard.writeText('info@avwsoft.com')
  }

  if (modalState === 'loading') {
    return (
      <div className='container'>
        <div className='loader'></div>
      </div>
    );
  }

  if (modalState === 'successful') {
    return (
      <>
        <div className='container'></div>
        <div className='message_container'>
          <div className='successful'>
            <div>
              <h6>Sent</h6>
              <img src={received} />
            </div>

            <p>
              Thank you for contacting us! Your message has been received, and we will get back to
              you as soon as possible.
            </p>
            <button onClick={exitModal}>OK</button>
          </div>
        </div>
      </>
    );
  }

  if (modalState === 'unsuccessful') {
    return (
      <>
        <div className='container'></div>
        <div className='message_container'>
          <div className='unsuccessful'>
            <div>
              <h6>Oops</h6>
              <img src={sadcloud} />
            </div>

            <p>
            Something went wrong. We apologize for the inconvenience. Please try again later or contact us at <span title='Click to copy' onClick={copyEmail}>info@avwsoft.com</span>.
            </p>
            <button onClick={exitModal}>OK</button>
          </div>
        </div>
      </>
    );
  }

  if (modalState === 'closed') {
    return null;
  }

  return null;
};

export default RequestModal;
