import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './heading.scss'

const Heading:FC<{getElementId: any}> = ({getElementId}) => {
  return (
    <div className='carousel_section_info'>
      <h1>
        A<span>V</span>WSOFT
      </h1>
      <h2>Modern software solutions, consulting, outstaffing and outsourcing.</h2>
      <button onClick={() => getElementId('get_in_touch')}>Get In Touch</button>
    </div>
  );
};

export default Heading;
