import { FC } from "react"

const AboutOurWorkItem:FC<{image: string, text: string, title: string}> = ({image, text, title}) => {
  return (
    <div className="about_our_work_item">
      <img src={image} alt="" />
      <h3>{title}</h3>
      <h4>{text}</h4>
    </div>
  )
}

export default AboutOurWorkItem
