import AboutOurWork from '../AboutOurWork';
import AboutUsSection from '../AboutUsSection';
import CarouselSection from '../CarouselSection';
import ServicesSection from '../ServicesSection';
import OurProcessSection from '../OurProcessSection';
import GetInTouchSection from '../GetInTouchSection';
import { FC } from 'react';

const HomePage:FC<{getElementId: any}> = ({getElementId}) => {
  
  return (
    <>
      <CarouselSection getElementId={getElementId}/>
      <ServicesSection getElementId={getElementId}/>
      <AboutOurWork />
      <OurProcessSection />
      <AboutUsSection />
      <GetInTouchSection />
    </>
  );
};

export default HomePage;
