import { FC } from "react"
import { useNavigate } from "react-router-dom"

const ServiceCard:FC<{img: string, title: string, description: string, getElementId: any, sectionId:string}> = ({img, title, description, getElementId, sectionId}) => {
  const navigate = useNavigate()
  const cardClick = () => {
    navigate('/services')
    getElementId(sectionId)  
  }
  return (
    <div className="service_card" onClick={cardClick}>
      <img src={img} alt="" />
      <h1>{title}</h1>
      <h2>{description}</h2>
      <button>Read More</button>
    </div>
  )
}

export default ServiceCard
