import { FC } from "react"

const LeftMenuItem:FC<{item:any, closeMenu?: any, itemClick: any}> = ({item, closeMenu, itemClick}) => {
  const {service, selected, sectionId} = item

  // const scrollToSection = (e: any, id: any) => {
  //   e?.preventDefault();
  //   if(closeMenu) {
  //     closeMenu()
  //   }
  //   const element = document.getElementById(id);
  //   element?.scrollIntoView({ behavior: 'smooth' });
  // };
  
  const scrollToSection = (e: any, id: any) => {
    itemClick(sectionId)
    e?.preventDefault();
    if (closeMenu) {
      closeMenu();
    }
    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - 90,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
    {selected ?
      <li style={{color: '#00ff00'}}>{service}</li>
      :
      <li onClick={(e) => scrollToSection(e, sectionId)}>{service}</li>
    }
    </>
  )
}

export default LeftMenuItem
