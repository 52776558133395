import ServiceCard from './ServiceCard';
import './servicesSection.scss';
import { images } from '../../configs';
import { FC } from 'react';

const ServicesSection:FC<{getElementId: any}> = ({getElementId}) => {
  const {services1,services2,services3,services4,services5,services6,services7,services8,services9} = images
  const services = [
    {sectionId: 'website_development',img: services1, title: 'Website Development', description: "🚀 Elevate your online presence with our innovative web development solutions, crafted for speed, scalability, and superior SEO performance. Unlock your digital potential today!"},
    {sectionId: 'uiux_design',img: services2, title: 'UI/UX Design', description: "🎨 Transform your digital presence with our exceptional UI/UX design solutions, meticulously crafted to captivate your audience and prioritize user engagement. Experience the difference today!"},
    {sectionId: 'data_analytics',img: services3, title: 'Data Analytics', description: "📊 Unleash the potential of your business insights with our comprehensive data analytics solutions. From custom data visualization interfaces to deep insights into visitor behavior, empower your decision-making with confidence. Experience the difference today!"},
    {sectionId: 'content_management_systems',img: services4, title: 'Content Management Systems', description: "🌐 Streamline your digital presence with our intuitive CMS solutions. Effortlessly manage your content from creation to publication, empowering your online narrative with ease. Experience the difference today! 🚀"},
    {sectionId: 'it_infrastructure',img: services5, title: 'IT Infrastructure', description: "🖥️ Quality IT infrastructure is the bedrock of successful software solutions. Fortify your digital foundation for reliability, scalability, and peak performance with our superior services. Contact us to elevate your digital success! 🌟"},
    {sectionId: 'application_development',img: services6, title: 'Application Development', description: "📱 Ready to elevate your mobile presence? Our application development services ensure reliability, versatility, and seamless performance across iOS and Android platforms. Contact us today to explore how we can bring your vision to life and propel your digital success! 🚀"},
    {sectionId: 'technology_consulting',img: services7, title: 'Technology Consulting', description: "💡 Maximize your business potential with personalized technology consulting solutions. Navigate the digital landscape confidently as we tailor cutting-edge technologies to your unique needs. Unleash innovation and drive your success forward! 🚀"},
    {sectionId: 'it_outstaffing_services',img: services8, title: 'IT Outstaffing Services', description: "🤝 Elevate your business with our IT outstaffing and outsourcing services. Scale effortlessly without managing an in-house team. Enjoy fast hiring, expert support, and cost savings. Propel your success today! 🚀"},
    {sectionId: 'custom_software_solutions',img: services9, title: 'Custom Software Solutions', description: "🛠️ Transform your business with custom software development. From concept to reality, we'll bring your custom ideas to life. Boost efficiency, productivity, and innovation. Contact us to unleash your success! 🚀"},
  ]

  return (
    <main className='our_services_section'>
      <h1>Our Services</h1>
      <div className='our_services_content'>
        {services.map((item) => {
          return <ServiceCard getElementId={getElementId} key={item.img} img={item.img} title={item.title} description={item.description} sectionId={item.sectionId}/>
        })}
      </div>
    </main>
  );
};

export default ServicesSection;
