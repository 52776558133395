import { useState, useEffect } from 'react';
import { servicesPageContent } from '../../configs';
import LeftMenuItem from './LeftMenuItem';
import './servicesPage.scss';
import ServicesPageItem from './ServicesPageItem';

const menuItems = [
  { service: 'Website Development', selected: false, sectionId: 'website_development' },
  { service: 'UI/UX Design', selected: false, sectionId: 'uiux_design' },
  { service: 'Data Analytics', selected: false, sectionId: 'data_analytics' },
  {
    service: 'Content Management Systems',
    selected: false,
    sectionId: 'content_management_systems',
  },
  { service: 'IT Infrastructure', selected: false, sectionId: 'it_infrastructure' },
  { service: 'Application Development', selected: false, sectionId: 'application_development' },
  { service: 'Technology Consulting', selected: false, sectionId: 'technology_consulting' },
  { service: 'IT Outstaffing Services', selected: false, sectionId: 'it_outstaffing_services' },
  { service: 'Custom Software Solutions', selected: false, sectionId: 'custom_software_solutions' },
];

const ServicesPage = () => {
  //TESTVI KOD VORY CHI ASHXATUM MOBILE U TABLET VIEWERI ZHAMANAK
  useEffect(() => {
    const observerOptions = {
      root: null, // Default is the viewport
      rootMargin: '0px',
      threshold: 0.6, // Trigger when 50% of the element is in view
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setLeftMenuItems(state => {
            return state.map(item => {
              if (item.sectionId === entry.target.id) {
                return { service: item.service, selected: true, sectionId: item.sectionId };
              } else {
                return { service: item.service, selected: false, sectionId: item.sectionId };
              }
            });
          });
        }
      });
    }, observerOptions);

    const sectionElements = document.querySelectorAll('.servicesPage_Item');
    sectionElements.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sectionElements.forEach(section => {
        observer.unobserve(section);
      });
    };
  }, []);

  //TESTVI KOD verevy

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [leftMenuItems, setLeftMenuItems] = useState(menuItems);
  const [mobileMode, setMobileMode] = useState(false);
  const [tabletMode, setTabletMode] = useState(false);
  const rightArrow = '>';
  const leftArrow = '<';

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 1300 && width > 700) {
        setTabletMode(true);
        setMobileMode(false);
      }
      if (width <= 700) {
        setMobileMode(true);
        setTabletMode(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openMenu = () => {
    setLeftMenuOpen(true);
  };

  const closeMenu = () => {
    setLeftMenuOpen(false);
  };

  const itemClick = (sectionId: string) => {
    setLeftMenuItems(state => {
      return state.map(item => {
        if (item.sectionId === sectionId) {
          return { service: item.service, selected: true, sectionId: item.sectionId };
        } else {
          return { service: item.service, selected: false, sectionId: item.sectionId };
        }
      });
    });
  }

  return (
    <div id='servicesPage' className='servicesPage'>
      {mobileMode && (
        <>
          <div
            className={`servicesPage_leftMenu_mobile ${
              leftMenuOpen ? 'servicesPage_leftMenu_open' : ''
            }`}
          >
            <ul>
              {leftMenuItems.map(item => {
                return <LeftMenuItem key={item.service} item={item} itemClick={itemClick} closeMenu={closeMenu} />;
              })}
            </ul>
            {leftMenuOpen ? (
              <div className='arrow arrow_open' onClick={closeMenu}>
                {leftArrow} {rightArrow}
              </div>
            ) : (
              <div className='arrow ' onClick={openMenu}>
                {leftArrow} {rightArrow}
              </div>
            )}
          </div>
          <div className='servicesPage_content'>
            {servicesPageContent?.map(item => {
              return <ServicesPageItem key={item.id} item={item} />;
            })}
          </div>
        </>
      )}

      {tabletMode && (
        <>
          <div
            className={`servicesPage_leftMenu_tablet ${
              leftMenuOpen ? 'servicesPage_leftMenu_open' : ''
            }`}
          >
            <ul>
              {leftMenuItems.map(item => {
                return <LeftMenuItem key={item.service} item={item} itemClick={itemClick} closeMenu={closeMenu} />;
              })}
            </ul>
            {leftMenuOpen ? (
              <div className='arrow arrow_open' onClick={closeMenu}>
                {leftArrow} {rightArrow}
              </div>
            ) : (
              <div className='arrow ' onClick={openMenu}>
                {leftArrow} {rightArrow}
              </div>
            )}
          </div>
          <div className='servicesPage_content'>
            {servicesPageContent?.map(item => {
              return <ServicesPageItem key={item.id} item={item} />;
            })}
          </div>
        </>
      )}

      {!tabletMode && !mobileMode && (
        <>
          <div
            className={`servicesPage_leftMenu ${leftMenuOpen ? 'servicesPage_leftMenu_open' : ''}`}
          >
            <ul>
              {leftMenuItems.map(item => {
                return <LeftMenuItem key={item.service} itemClick={itemClick} item={item} />;
              })}
            </ul>
            {leftMenuOpen ? (
              <div className='arrow arrow_open' onClick={closeMenu}>
                {leftArrow} {rightArrow}
              </div>
            ) : (
              <div className='arrow ' onClick={openMenu}>
                {leftArrow} {rightArrow}
              </div>
            )}
          </div>
          <div className='servicesPage_content'>
            {servicesPageContent?.map(item => {
              return <ServicesPageItem key={item.id} item={item} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ServicesPage;
