import { FC } from "react"

const ServicesPageItem:FC<{item: any}> = ({item}) => {
  const {id,texts, textList, endText, img, title} = item

  return (
    <div id={id} className="servicesPage_Item">
      <div className="img_container">
        <img src={img}/>
      </div>
      <h1>{title}</h1>
      {texts.map((text: any) => {
        return <p key={text} className="texts">{text}</p>
      })}
      {textList.map((text: any) => {
        return <p key={text} className="textList">{text}</p>
      })}
      <p className="endText">{endText}</p>
    </div>
  )
}

export default ServicesPageItem
