import { useState } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import ServicesPage from './components/ServicesPage';
import ScrollToTop from './components/ScrollToTop';
import ScrollToElement from './components/ScrollToElement';

import { Route, Routes } from 'react-router-dom';

import './assets/styles/main.scss'
import './App.scss'

const App = () => {
  const [elementId, setElementId] = useState<string>('')

  const getElementId = (id:string) => {
    setElementId(id)
  }
  return (
    <div id='home'className="page_container">
      <Header getElementId={getElementId}/>
      <ScrollToTop />
      <ScrollToElement elementId={elementId}/>
        <Routes>
          <Route path='/' element={<HomePage getElementId={getElementId}/>}/>
          <Route path='/services' element={<ServicesPage/>} />
        </Routes>
        
      <Footer getElementId={getElementId}/>

      
    </div>
  );
}

export default App;
