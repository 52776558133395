import './aboutUsSection.scss';
import { images } from '../../configs';

const AboutUsSection = () => {
  const {aboutus1} = images
  return (
    <section id='aboutUs' className='about_us_section'>
      <img src={aboutus1} alt="" />
      <div className='about_us_content'>
        <h3>About AVWSOFT</h3>
        <p>
          We are a dynamic software development company, driven by innovation at our core.
          Specializing in innovative solutions for businesses of all sizes, our team of seasoned
          developers, designers, and project managers is devoted to delivering top-notch products
          that surpass our clients' expectations. Recognizing the uniqueness of every business and
          its distinct needs, we collaborate closely with our clients to comprehend their objectives
          and customize our solutions accordingly. Employing cutting-edge technologies aligned with
          industry standards ensures optimal outcomes. Offering a range of services including web
          and mobile app development, custom software development, UI/UX design, and more, at
          AVWsoft, we pride ourselves on delivering projects promptly and within budget. Committed
          to exceptional customer service and fostering long-term client relationships, if you seek
          a reliable and professional software development partner, AVWsoft is your ultimate
          destination. Reach out today to explore how we can empower your business growth.
        </p>
      </div>
    </section>
  );
};

export default AboutUsSection;
