import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.scss';
import { images } from '../../configs';
import { Link } from 'react-router-dom';
import { FC } from 'react';

const Header:FC<{getElementId: any}> = ({getElementId}) => {
  const { logo, menu } = images;
  const [mobileMode, setMobileMode] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState<Boolean | null>(null);
  const [toggleLeft, setToggleLeft] = useState(false);
  const [toggleRight, setToggleRight] = useState(false);
  const [exactly1000, setExactly1000] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 1000) {
        setMobileMode(true);
        setExactly1000(false);
      } else if (width === 1000) {
        setMobileMode(true);
        setExactly1000(true);
      } else {
        setMobileMode(false);
        setExactly1000(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openNav = () => {
    setMobileNavOpen(true);
    setToggleRight(false);
    setToggleLeft(true);
  };

  const closeNav = () => {
    setMobileNavOpen(false);
    setToggleLeft(false);
    setToggleRight(true);
  };

  const linkClickAbout = () => {
    closeNav()
    getElementId('about_our_work')
  }

  const linkClickContact = () => {
    closeNav()
    getElementId('get_in_touch')
  }

  const linkClickHome = () => {
    closeNav()
    getElementId('carousel_section')
  }

  const logoClick = () => {
    navigate('/'); 
    getElementId('carousel_section')
  }

  return (
    <>
      {(mobileMode || exactly1000) && (
        <>
          <nav
            className={`header_navigation_mobile ${
              mobileNavOpen ? 'header_navigation_mobile_open' : ''
            }`}
          >
            <div className='close_btn_container' onClick={closeNav}>
              <span>×</span>
            </div>
            <div className='mobile_navigation_items'>
              <Link to='/' onClick={linkClickHome}>
                Home
              </Link>
              <Link to='/services' onClick={closeNav}>
                Services
              </Link>
              <Link to='/' onClick={linkClickAbout}>
                About
              </Link>
              <Link to='/' onClick={linkClickContact}>
                Contact Us
              </Link>
            </div>
          </nav>
        </>
      )}
      <header className='header_container'>
        <img className='header_logo' src={logo} alt='logo' width={55} height={55} onClick={logoClick}/>

        {!mobileMode && (
          <nav className='header_navigation'>
            <Link to='/' onClick={linkClickHome}>Home</Link>
            <Link to='/services'>Services</Link>
            <Link to='/' onClick={linkClickAbout}>About</Link>
            <Link to='/' onClick={linkClickContact}>Contact Us</Link>
          </nav>
        )}

        {(mobileMode || exactly1000) && (
          <>
            <img
              className={`header_mobile_menu ${toggleLeft ? 'toggleLeft' : ''} ${
                toggleRight ? 'toggleRight' : ''
              }`}
              src={menu}
              onClick={openNav}
            ></img>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
