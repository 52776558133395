export const countries = [
  { name: 'Afghanistan', flag: '🇦🇫', code: 'AF', phone: '+93' },
  { name: 'Albania', flag: '🇦🇱', code: 'AL', phone: '+355' },
  { name: 'Algeria', flag: '🇩🇿', code: 'DZ', phone: '+213' },
  { name: 'Andorra', flag: '🇦🇩', code: 'AD', phone: '+376' },
  { name: 'Angola', flag: '🇦🇴', code: 'AO', phone: '+244' },
  { name: 'Antigua and Barbuda', flag: '🇦🇬', code: 'AG', phone: '+1' },
  { name: 'Argentina', flag: '🇦🇷', code: 'AR', phone: '+54' },
  { name: 'Armenia', flag: '🇦🇲', code: 'AM', phone: '+374' },
  { name: 'Australia', flag: '🇦🇺', code: 'AU', phone: '+61' },
  { name: 'Austria', flag: '🇦🇹', code: 'AT', phone: '+43' },
  { name: 'Azerbaijan', flag: '🇦🇿', code: 'AZ', phone: '+994' },
  { name: 'Bahamas', flag: '🇧🇸', code: 'BS', phone: '+1' },
  { name: 'Bahrain', flag: '🇧🇭', code: 'BH', phone: '+973' },
  { name: 'Bangladesh', flag: '🇧🇩', code: 'BD', phone: '+880' },
  { name: 'Barbados', flag: '🇧🇧', code: 'BB', phone: '+1' },
  { name: 'Belarus', flag: '🇧🇾', code: 'BY', phone: '+375' },
  { name: 'Belgium', flag: '🇧🇪', code: 'BE', phone: '+32' },
  { name: 'Belize', flag: '🇧🇿', code: 'BZ', phone: '+501' },
  { name: 'Benin', flag: '🇧🇯', code: 'BJ', phone: '+229' },
  { name: 'Bhutan', flag: '🇧🇹', code: 'BT', phone: '+975' },
  { name: 'Bolivia', flag: '🇧🇴', code: 'BO', phone: '+591' },
  { name: 'Bosnia and Herzegovina', flag: '🇧🇦', code: 'BA', phone: '+387' },
  { name: 'Botswana', flag: '🇧🇼', code: 'BW', phone: '+267' },
  { name: 'Brazil', flag: '🇧🇷', code: 'BR', phone: '+55' },
  { name: 'Brunei Darussalam', flag: '🇧🇳', code: 'BN', phone: '+673' },
  { name: 'Bulgaria', flag: '🇧🇬', code: 'BG', phone: '+359' },
  { name: 'Burkina Faso', flag: '🇧🇫', code: 'BF', phone: '+226' },
  { name: 'Burundi', flag: '🇧🇮', code: 'BI', phone: '+257' },
  { name: 'Cabo Verde', flag: '🇨🇻', code: 'CV', phone: '+238' },
  { name: 'Cambodia', flag: '🇰🇭', code: 'KH', phone: '+855' },
  { name: 'Cameroon', flag: '🇨🇲', code: 'CM', phone: '+237' },
  { name: 'Canada', flag: '🇨🇦', code: 'CA', phone: '+1' },
  { name: 'Central African Republic', flag: '🇨🇫', code: 'CF', phone: '+236' },
  { name: 'Chad', flag: '🇹🇩', code: 'TD', phone: '+235' },
  { name: 'Chile', flag: '🇨🇱', code: 'CL', phone: '+56' },
  { name: 'China', flag: '🇨🇳', code: 'CN', phone: '+86' },
  { name: 'Colombia', flag: '🇨🇴', code: 'CO', phone: '+57' },
  { name: 'Comoros', flag: '🇰🇲', code: 'KM', phone: '+269' },
  { name: 'Congo', flag: '🇨🇬', code: 'CG', phone: '+242' },
  { name: 'Costa Rica', flag: '🇨🇷', code: 'CR', phone: '+506' },
  { name: 'Croatia', flag: '🇭🇷', code: 'HR', phone: '+385' },
  { name: 'Cuba', flag: '🇨🇺', code: 'CU', phone: '+53' },
  { name: 'Cyprus', flag: '🇨🇾', code: 'CY', phone: '+357' },
  { name: 'Czech Republic', flag: '🇨🇿', code: 'CZ', phone: '+420' },
  { name: 'Denmark', flag: '🇩🇰', code: 'DK', phone: '+45' },
  { name: 'Djibouti', flag: '🇩🇯', code: 'DJ', phone: '+253' },
  { name: 'Dominica', flag: '🇩🇲', code: 'DM', phone: '+1' },
  { name: 'Dominican Republic', flag: '🇩🇴', code: 'DO', phone: '+1' },
  { name: 'Ecuador', flag: '🇪🇨', code: 'EC', phone: '+593' },
  { name: 'Egypt', flag: '🇪🇬', code: 'EG', phone: '+20' },
  { name: 'El Salvador', flag: '🇸🇻', code: 'SV', phone: '+503' },
  { name: 'Equatorial Guinea', flag: '🇬🇶', code: 'GQ', phone: '+240' },
  { name: 'Eritrea', flag: '🇪🇷', code: 'ER', phone: '+291' },
  { name: 'Estonia', flag: '🇪🇪', code: 'EE', phone: '+372' },
  { name: 'Eswatini', flag: '🇸🇿', code: 'SZ', phone: '+268' },
  { name: 'Ethiopia', flag: '🇪🇹', code: 'ET', phone: '+251' },
  { name: 'Fiji', flag: '🇫🇯', code: 'FJ', phone: '+679' },
  { name: 'Finland', flag: '🇫🇮', code: 'FI', phone: '+358' },
  { name: 'France', flag: '🇫🇷', code: 'FR', phone: '+33' },
  { name: 'Gabon', flag: '🇬🇦', code: 'GA', phone: '+241' },
  { name: 'Gambia', flag: '🇬🇲', code: 'GM', phone: '+220' },
  { name: 'Georgia', flag: '🇬🇪', code: 'GE', phone: '+995' },
  { name: 'Germany', flag: '🇩🇪', code: 'DE', phone: '+49' },
  { name: 'Ghana', flag: '🇬🇭', code: 'GH', phone: '+233' },
  { name: 'Greece', flag: '🇬🇷', code: 'GR', phone: '+30' },
  { name: 'Grenada', flag: '🇬🇩', code: 'GD', phone: '+1' },
  { name: 'Guatemala', flag: '🇬🇹', code: 'GT', phone: '+502' },
  { name: 'Guinea', flag: '🇬🇳', code: 'GN', phone: '+224' },
  { name: 'Guinea-Bissau', flag: '🇬🇼', code: 'GW', phone: '+245' },
  { name: 'Guyana', flag: '🇬🇾', code: 'GY', phone: '+592' },
  { name: 'Haiti', flag: '🇭🇹', code: 'HT', phone: '+509' },
  { name: 'Honduras', flag: '🇭🇳', code: 'HN', phone: '+504' },
  { name: 'Hungary', flag: '🇭🇺', code: 'HU', phone: '+36' },
  { name: 'Iceland', flag: '🇮🇸', code: 'IS', phone: '+354' },
  { name: 'India', flag: '🇮🇳', code: 'IN', phone: '+91' },
  { name: 'Indonesia', flag: '🇮🇩', code: 'ID', phone: '+62' },
  { name: 'Iran', flag: '🇮🇷', code: 'IR', phone: '+98' },
  { name: 'Iraq', flag: '🇮🇶', code: 'IQ', phone: '+964' },
  { name: 'Ireland', flag: '🇮🇪', code: 'IE', phone: '+353' },
  { name: 'Israel', flag: '🇮🇱', code: 'IL', phone: '+972' },
  { name: 'Italy', flag: '🇮🇹', code: 'IT', phone: '+39' },
  { name: 'Jamaica', flag: '🇯🇲', code: 'JM', phone: '+1' },
  { name: 'Japan', flag: '🇯🇵', code: 'JP', phone: '+81' },
  { name: 'Jordan', flag: '🇯🇴', code: 'JO', phone: '+962' },
  { name: 'Kazakhstan', flag: '🇰🇿', code: 'KZ', phone: '+7' },
  { name: 'Kenya', flag: '🇰🇪', code: 'KE', phone: '+254' },
  { name: 'Kiribati', flag: '🇰🇮', code: 'KI', phone: '+686' },
  { name: 'Korea (North)', flag: '🇰🇵', code: 'KP', phone: '+850' },
  { name: 'Korea (South)', flag: '🇰🇷', code: 'KR', phone: '+82' },
  { name: 'Kuwait', flag: '🇰🇼', code: 'KW', phone: '+965' },
  { name: 'Kyrgyzstan', flag: '🇰🇬', code: 'KG', phone: '+996' },
  { name: 'Lao PDR', flag: '🇱🇦', code: 'LA', phone: '+856' },
  { name: 'Latvia', flag: '🇱🇻', code: 'LV', phone: '+371' },
  { name: 'Lebanon', flag: '🇱🇧', code: 'LB', phone: '+961' },
  { name: 'Lesotho', flag: '🇱🇸', code: 'LS', phone: '+266' },
  { name: 'Liberia', flag: '🇱🇷', code: 'LR', phone: '+231' },
  { name: 'Libya', flag: '🇱🇾', code: 'LY', phone: '+218' },
  { name: 'Liechtenstein', flag: '🇱🇮', code: 'LI', phone: '+423' },
  { name: 'Lithuania', flag: '🇱🇹', code: 'LT', phone: '+370' },
  { name: 'Luxembourg', flag: '🇱🇺', code: 'LU', phone: '+352' },
  { name: 'Macedonia (Republic)', flag: '🇲🇰', code: 'MK', phone: '+389' },
  { name: 'Madagascar', flag: '🇲🇬', code: 'MG', phone: '+261' },
  { name: 'Malawi', flag: '🇲🇼', code: 'MW', phone: '+265' },
  { name: 'Malaysia', flag: '🇲🇾', code: 'MY', phone: '+60' },
  { name: 'Maldives', flag: '🇲🇻', code: 'MV', phone: '+960' },
  { name: 'Mali', flag: '🇲🇱', code: 'ML', phone: '+223' },
  { name: 'Malta', flag: '🇲🇹', code: 'MT', phone: '+356' },
  { name: 'Marshall Islands', flag: '🇲🇭', code: 'MH', phone: '+692' },
  { name: 'Mauritania', flag: '🇲🇷', code: 'MR', phone: '+222' },
  { name: 'Mauritius', flag: '🇲🇺', code: 'MU', phone: '+230' },
  { name: 'Mexico', flag: '🇲🇽', code: 'MX', phone: '+52' },
  { name: 'Micronesia', flag: '🇫🇲', code: 'FM', phone: '+691' },
  { name: 'Moldova', flag: '🇲🇩', code: 'MD', phone: '+373' },
  { name: 'Monaco', flag: '🇲🇨', code: 'MC', phone: '+377' },
  { name: 'Mongolia', flag: '🇲🇳', code: 'MN', phone: '+976' },
  { name: 'Montenegro', flag: '🇲🇪', code: 'ME', phone: '+382' },
  { name: 'Morocco', flag: '🇲🇦', code: 'MA', phone: '+212' },
  { name: 'Mozambique', flag: '🇲🇿', code: 'MZ', phone: '+258' },
  { name: 'Myanmar', flag: '🇲🇲', code: 'MM', phone: '+95' },
  { name: 'Namibia', flag: '🇳🇦', code: 'NA', phone: '+264' },
  { name: 'Nauru', flag: '🇳🇷', code: 'NR', phone: '+674' },
  { name: 'Nepal', flag: '🇳🇵', code: 'NP', phone: '+977' },
  { name: 'Netherlands', flag: '🇳🇱', code: 'NL', phone: '+31' },
  { name: 'New Zealand', flag: '🇳🇿', code: 'NZ', phone: '+64' },
  { name: 'Nicaragua', flag: '🇳🇮', code: 'NI', phone: '+505' },
  { name: 'Niger', flag: '🇳🇪', code: 'NE', phone: '+227' },
  { name: 'Nigeria', flag: '🇳🇬', code: 'NG', phone: '+234' },
  { name: 'Norway', flag: '🇳🇴', code: 'NO', phone: '+47' },
  { name: 'Oman', flag: '🇴🇲', code: 'OM', phone: '+968' },
  { name: 'Pakistan', flag: '🇵🇰', code: 'PK', phone: '+92' },
  { name: 'Palau', flag: '🇵🇼', code: 'PW', phone: '+680' },
  { name: 'Palestinian Territories', flag: '🇵🇸', code: 'PS', phone: '+970' },
  { name: 'Panama', flag: '🇵🇦', code: 'PA', phone: '+507' },
  { name: 'Papua New Guinea', flag: '🇵🇬', code: 'PG', phone: '+675' },
  { name: 'Paraguay', flag: '🇵🇾', code: 'PY', phone: '+595' },
  { name: 'Peru', flag: '🇵🇪', code: 'PE', phone: '+51' },
  { name: 'Philippines', flag: '🇵🇭', code: 'PH', phone: '+63' },
  { name: 'Poland', flag: '🇵🇱', code: 'PL', phone: '+48' },
  { name: 'Portugal', flag: '🇵🇹', code: 'PT', phone: '+351' },
  { name: 'Qatar', flag: '🇶🇦', code: 'QA', phone: '+974' },
  { name: 'Romania', flag: '🇷🇴', code: 'RO', phone: '+40' },
  { name: 'Russia', flag: '🇷🇺', code: 'RU', phone: '+7' },
  { name: 'Rwanda', flag: '🇷🇼', code: 'RW', phone: '+250' },
  { name: 'Saint Kitts and Nevis', flag: '🇰🇳', code: 'KN', phone: '+1' },
  { name: 'Saint Lucia', flag: '🇱🇨', code: 'LC', phone: '+1' },
  { name: 'Saint Vincent and the Grenadines', flag: '🇻🇨', code: 'VC', phone: '+1' },
  { name: 'Samoa', flag: '🇼🇸', code: 'WS', phone: '+685' },
  { name: 'San Marino', flag: '🇸🇲', code: 'SM', phone: '+378' },
  { name: 'Sao Tome and Principe', flag: '🇸🇹', code: 'ST', phone: '+239' },
  { name: 'Saudi Arabia', flag: '🇸🇦', code: 'SA', phone: '+966' },
  { name: 'Senegal', flag: '🇸🇳', code: 'SN', phone: '+221' },
  { name: 'Serbia', flag: '🇷🇸', code: 'RS', phone: '+381' },
  { name: 'Seychelles', flag: '🇸🇨', code: 'SC', phone: '+248' },
  { name: 'Sierra Leone', flag: '🇸🇱', code: 'SL', phone: '+232' },
  { name: 'Singapore', flag: '🇸🇬', code: 'SG', phone: '+65' },
  { name: 'Slovakia', flag: '🇸🇰', code: 'SK', phone: '+421' },
  { name: 'Slovenia', flag: '🇸🇮', code: 'SI', phone: '+386' },
  { name: 'Solomon Islands', flag: '🇸🇧', code: 'SB', phone: '+677' },
  { name: 'Somalia', flag: '🇸🇴', code: 'SO', phone: '+252' },
  { name: 'South Africa', flag: '🇿🇦', code: 'ZA', phone: '+27' },
  { name: 'South Sudan', flag: '🇸🇸', code: 'SS', phone: '+211' },
  { name: 'Spain', flag: '🇪🇸', code: 'ES', phone: '+34' },
  { name: 'Sri Lanka', flag: '🇱🇰', code: 'LK', phone: '+94' },
  { name: 'Sudan', flag: '🇸🇩', code: 'SD', phone: '+249' },
  { name: 'Suriname', flag: '🇸🇷', code: 'SR', phone: '+597' },
  { name: 'Sweden', flag: '🇸🇪', code: 'SE', phone: '+46' },
  { name: 'Switzerland', flag: '🇨🇭', code: 'CH', phone: '+41' },
  { name: 'Syria', flag: '🇸🇾', code: 'SY', phone: '+963' },
  { name: 'Taiwan', flag: '🇹🇼', code: 'TW', phone: '+886' },
  { name: 'Tajikistan', flag: '🇹🇯', code: 'TJ', phone: '+992' },
  { name: 'Tanzania', flag: '🇹🇿', code: 'TZ', phone: '+255' },
  { name: 'Thailand', flag: '🇹🇭', code: 'TH', phone: '+66' },
  { name: 'Timor-Leste', flag: '🇹🇱', code: 'TL', phone: '+670' },
  { name: 'Togo', flag: '🇹🇬', code: 'TG', phone: '+228' },
  { name: 'Tonga', flag: '🇹🇴', code: 'TO', phone: '+676' },
  { name: 'Trinidad and Tobago', flag: '🇹🇹', code: 'TT', phone: '+1' },
  { name: 'Tunisia', flag: '🇹🇳', code: 'TN', phone: '+216' },
  { name: 'Turkey', flag: '🇹🇷', code: 'TR', phone: '+90' },
  { name: 'Turkmenistan', flag: '🇹🇲', code: 'TM', phone: '+993' },
  { name: 'Tuvalu', flag: '🇹🇻', code: 'TV', phone: '+688' },
  { name: 'Uganda', flag: '🇺🇬', code: 'UG', phone: '+256' },
  { name: 'Ukraine', flag: '🇺🇦', code: 'UA', phone: '+380' },
  { name: 'United Arab Emirates', flag: '🇦🇪', code: 'AE', phone: '+971' },
  { name: 'United Kingdom', flag: '🇬🇧', code: 'GB', phone: '+44' },
  { name: 'United States of America', flag: '🇺🇸', code: 'US', phone: '+1' },
  { name: 'Uruguay', flag: '🇺🇾', code: 'UY', phone: '+598' },
  { name: 'Uzbekistan', flag: '🇺🇿', code: 'UZ', phone: '+998' },
  { name: 'Vanuatu', flag: '🇻🇺', code: 'VU', phone: '+678' },
  { name: 'Vatican City', flag: '🇻🇦', code: 'VA', phone: '+379' },
  { name: 'Venezuela', flag: '🇻🇪', code: 'VE', phone: '+58' },
  { name: 'Vietnam', flag: '🇻🇳', code: 'VN', phone: '+84' },
  { name: 'Yemen', flag: '🇾🇪', code: 'YE', phone: '+967' },
  { name: 'Zambia', flag: '🇿🇲', code: 'ZM', phone: '+260' },
  { name: 'Zimbabwe', flag: '🇿🇼', code: 'ZW', phone: '+263' },
  { name: 'Bahamas', flag: '🇧🇸', code: 'BS', phone: '+1-242' },
  { name: 'Barbados', flag: '🇧🇧', code: 'BB', phone: '+1-246' },
  { name: 'Antigua and Barbuda', flag: '🇦🇬', code: 'AG', phone: '+1-268' },
  { name: 'St. Vincent and the Grenadines', flag: '🇻🇨', code: 'VC', phone: '+1-784' },
  { name: 'St. Kitts and Nevis', flag: '🇰🇳', code: 'KN', phone: '+1-869' },
];



export const images = {
  aboutourwork1: 'https://i.ibb.co/27yMWWW/aboutourwork1.png',
  aboutourwork2: 'https://i.ibb.co/7kbKZVX/aboutourwork2.png ',
  aboutourwork3: 'https://i.ibb.co/88bcRZf/aboutourwork3.png',
  aboutourwork4: 'https://i.ibb.co/hBTbr5y/aboutourwork4.png',
  aboutus1: 'https://i.ibb.co/z76rc1K/aboutus1.jpg',
  bgImage1: 'https://i.ibb.co/kyFWjs5/bgImage1.jpg',
  bgImage2: 'https://i.ibb.co/6JyTXg7/bgImage2.png',
  bgImage3: 'https://i.ibb.co/54NbCNM/bgImage3.jpg',
  bgImage4: 'https://i.ibb.co/V2hxW2B/bgImage4.png',
  copyrightlogo: 'https://i.ibb.co/gTMs0DL/copyrightlogo.png',
  emaillogo: 'https://i.ibb.co/7CH1JWB/emaillogo.png',
  instalogo: 'https://i.ibb.co/fD1bQLs/instalogo.png',
  logo: 'https://i.ibb.co/5hN3y1b/logo.png',
  map: 'https://i.ibb.co/LNVZ7dJ/map.png',
  menu: 'https://i.ibb.co/HY4bhyY/menu.png',
  phonelogo: 'https://i.ibb.co/dpJDFKC/phonelogo.png',
  process1: 'https://i.ibb.co/fr87hxq/process1.png',
  process2: 'https://i.ibb.co/gPp3prG/process2.png',
  process3: 'https://i.ibb.co/C6nRd84/process3.png',
  process4: 'https://i.ibb.co/4gpbk4r/process4.png',
  rectangleforprocesses: 'https://i.ibb.co/fk7Tk6k/rectangle-For-Processes.png',
  services1: 'https://i.ibb.co/sFfFCmw/services1.png',
  services2: 'https://i.ibb.co/j6xtSCH/services2.png',
  services3: 'https://i.ibb.co/Ns2bDVQ/services3.png',
  services4: 'https://i.ibb.co/wcmB3sB/services4.png',
  services5: 'https://i.ibb.co/yBRSkVQ/services5.png',
  services6: 'https://i.ibb.co/D973MJF/services6.png',
  services7: 'https://i.ibb.co/WKbvL7X/services7.png',
  services8: 'https://i.ibb.co/DR86Qbm/services8.png',
  services9: 'https://i.ibb.co/QcF9d5g/services9.png',
  test: 'https://i.ibb.co/XZxMq0Q/test.png',
  received: 'https://i.ibb.co/nDNKwLN/received.png',
  sadcloud: 'https://i.ibb.co/r0y5Pzx/sadcloud.png'
  // map: 'https://i.ibb.co/F6SRw9m/map.png'
};

export const servicesPageContent = [
  {
    title: 'Website Development',
    id: 'website_development',
    img: 'https://i.ibb.co/sFfFCmw/services1.png',
    texts: [
      '🚀 Elevate Your Online Presence with Cutting-Edge Web Development Solutions! 🌐',
      'Are you ready to stand out in the digital world? Our web development services offer the perfect blend of innovation, speed, and scalability to catapult your business to new heights.',
      'With a relentless focus on quality, we craft bespoke websites tailored to your unique needs. Our expert team ensures fast development without compromising on excellence. From concept to launch, we streamline the process, delivering results that exceed expectations.',
      'Embrace the future with responsive layouts that adapt seamlessly to any device, ensuring an exceptional user experience across desktops, tablets, and smartphones. Our commitment to utilizing the latest technologies guarantees that your website is not only visually stunning but also technically advanced.',
      "But we don't stop there. Our dedication to SEO excellence ensures that your website not only looks great but also ranks higher in search engine results. We implement best practices to enhance visibility, drive traffic, and boost your online presence.",
      'Experience the difference with our web development solutions:',
    ],
    textList: [
      '✅ High-quality craftsmanship',
      '✅ Rapid development turnaround',
      '✅ Scalable architecture',
      '✅ Responsive design for all devices',
      '✅ Superior SEO performance',
      '✅ Integration of the latest technologies',
    ],
    endText:
      'Take the first step toward a brighter digital future. Contact us today to unlock the full potential of your online presence!',
  },
  {
    title: 'UI/UX Design',
    id: 'uiux_design',
    img: 'https://i.ibb.co/j6xtSCH/services2.png',
    texts: [
      '🎨 Elevate Your Digital Presence with Unique and Exceptional UI/UX Design Solutions! 🌟',
      'Ready to captivate your audience from the moment they land on your website or app? Our UI/UX design services combine creativity, functionality, and user-centricity to craft unique digital experiences that set you apart from the competition.',
      'At the heart of our approach lies a commitment to delivering unparalleled quality. Our seasoned designers meticulously craft interfaces that not only look stunning but also prioritize user engagement and satisfaction. From intuitive navigation to seamless interactions, every aspect of our designs is optimized to delight your audience.',
      'Speed is of the essence, and we understand the importance of swift development without sacrificing excellence. Our agile design process ensures rapid prototyping and iteration, allowing us to bring your vision to life in record time while maintaining the highest standards of craftsmanship.',
      "Scalability is key in today's dynamic digital landscape, and our designs are built to grow with your business. Whether you're launching a startup or expanding your enterprise, our scalable solutions adapt effortlessly to accommodate your evolving needs and aspirations.",
      'Embrace the future of digital design with responsive layouts that effortlessly adapt to various screen sizes and devices. Our designs are not only visually captivating but also function flawlessly across desktops, tablets, and mobile devices, ensuring a consistent and enjoyable user experience every time.',
      'Experience the difference with our UI/UX design solutions:',
    ],
    textList: [
      '✅ Unique and exceptional creativity',
      '✅ Rapid prototyping and iteration',
      '✅ Scalable designs for future growth',
      '✅ Responsive layouts for all devices',
      '✅ Integration of the latest UI/UX technologies',
    ],
    endText:
      'Unlock the full potential of your digital experience. Get in touch with us today to embark on a journey of innovation and user-centric design excellence!',
  },
  {
    title: 'Data Analytics',
    id: 'data_analytics',
    img: 'https://i.ibb.co/Ns2bDVQ/services3.png',
    texts: [
      '📊 Harness the Power of Data with Our Comprehensive Data Analytics Solutions! 🚀',
      "In today's digital landscape, data is the fuel that drives success. Are you ready to unlock the full potential of your business insights? Our data analytics services offer a holistic approach to understanding and leveraging data to drive informed decision-making and propel your business forward.",
      "At the core of our data analytics solutions is the ability to provide custom data visualization interfaces for your website, empowering you to present complex data in an intuitive and visually engaging manner. Whether you're showcasing key performance indicators or revealing insightful trends, our data visualization tools transform raw data into actionable insights.",
      "But we don't just stop at visualization. We equip you with a comprehensive suite of tools designed to analyze all types of data related to your website, including visitor behavior, engagement metrics, and more. Our goal is to provide you with a deep understanding of your audience, enabling you to tailor your strategies for maximum impact.",
      "Data analytics isn't just another service; it's the cornerstone of driving business success. By harnessing the power of data, you gain a competitive edge, identifying opportunities, mitigating risks, and making data-driven decisions with confidence.",
      'Key highlights of our data analytics solutions:',
    ],
    textList: [
      '📈 Custom data visualization interfaces for your website',
      '🔍 Comprehensive tools for analyzing all types of website data',
      '📊 Empowerment through deep insights into visitor behavior and engagement metrics',
      '🚀 Driving business success through data-driven decision-making',
    ],
    endText:
      "Don't leave your business success to chance. Embrace the power of data analytics and take control of your destiny. Contact us today to discover how our data analytics solutions can transform your business trajectory!",
  },
  {
    title: 'Content Management Systems',
    id: 'content_management_systems',
    img: 'https://i.ibb.co/wcmB3sB/services4.png',
    texts: [
      '🌐 Empower Your Digital Presence with Our Intuitive CMS Solutions! 🚀',
      'In the fast-paced digital world, managing your content efficiently is key to staying ahead of the curve. Are you ready to streamline your content management process and take control of your online narrative? Our CMS (Content Management System) solutions offer a seamless blend of power and simplicity, enabling you to manage your digital assets with ease.',
      'At the heart of our CMS offerings is a deep understanding of the importance of effective content management. We recognize that content is the lifeblood of your digital presence, driving engagement, attracting audiences, and shaping perceptions. Our CMS solutions empower you to create, organize, and publish content effortlessly, ensuring that your message resonates with your target audience.',
      "But what sets our CMS apart is its intuitive and easy-to-use interface. We understand that not everyone is a tech whiz, which is why we've designed our CMS with the end user in mind. Whether you're a seasoned content creator or a novice, our intuitive interface makes managing your content a breeze. Say goodbye to complicated workflows and hello to streamlined content management.",
      'But the importance of CMS extends beyond just ease of use. A robust CMS is the backbone of your digital strategy, enabling you to adapt and evolve in response to changing market dynamics. From updating your website content to launching new marketing campaigns, our CMS solutions provide the flexibility and agility you need to stay ahead of the competition.',
      'Key highlights of our CMS solutions:',
    ],
    textList: [
      '✨ Intuitive and easy-to-use interface for seamless content management',
      '🌐 Empowerment to create, organize, and publish content effortlessly',
      '🚀 Flexibility and agility to adapt to changing market dynamics',
      '📈 Streamlined workflows for maximum efficiency',
    ],
    endText:
      "Don't let outdated content management practices hold you back. Embrace the power of our CMS solutions and unlock the full potential of your digital presence. Contact us today to discover how our intuitive CMS interfaces can revolutionize the way you manage your content!",
  },
  {
    title: 'IT Infrastructure',
    id: 'it_infrastructure',
    img: 'https://i.ibb.co/yBRSkVQ/services5.png',

    texts: [
      '🖥️ Elevate Your Software Solutions with Robust IT Infrastructure! 🌟',
      "In today's digital landscape, the backbone of any successful software solution lies in its IT infrastructure. Are you ready to lay the foundation for reliability, scalability, and performance? Our IT infrastructure services offer a comprehensive approach to building and maintaining the technological framework that powers your business.",
      "At the core of our IT infrastructure solutions is a deep appreciation for its importance in the real world. We understand that a solid infrastructure is not just a technical necessity; it's the bedrock upon which your entire digital ecosystem is built. From seamless data access to uninterrupted service delivery, every aspect of your software solution relies on a robust IT infrastructure.",
      'But the impact of IT infrastructure extends far beyond just ensuring the smooth operation of your software. It plays a pivotal role in the quality of your software solutions, influencing factors such as reliability, security, and performance. By investing in a well-designed and properly maintained infrastructure, you lay the groundwork for delivering superior software solutions that exceed client expectations.',
      "Moreover, a robust IT infrastructure is essential for serving clients in the long term. It provides the scalability and flexibility needed to adapt to changing business requirements and technological advancements. Whether you're scaling up to accommodate growing demand or integrating new features to stay ahead of the competition, a strong infrastructure ensures that your software solutions can evolve with your business.",
      'Key highlights of our IT infrastructure solutions:',
    ],
    textList: [
      '🔧 Foundation for reliability, scalability, and performance',
      '🚀 Impact on the quality of software solutions',
      '🔒 Ensuring reliability, security, and performance',
      '🌐 Flexibility and scalability for long-term client satisfaction',
    ],
    endText:
      "Don't underestimate the importance of IT infrastructure in powering your software solutions. Take the first step towards building a rock-solid foundation for your digital success. Contact us today to discover how our IT infrastructure solutions can propel your business to new heights!",
  },
  {
    title: 'Application Development',
    id: 'application_development',

    img: 'https://i.ibb.co/D973MJF/services6.png',

    texts: [
      "📱 In today's digital realm, the cornerstone of successful mobile applications lies in their development process. Are you prepared to establish a solid foundation for reliability, versatility, and performance across iOS and Android platforms? Our application development services offer a comprehensive solution, ensuring your mobile presence resonates seamlessly with users on all devices and platforms.",
      "At the heart of our approach to iOS and Android application development is a profound understanding of its significance in the modern landscape. We recognize that crafting intuitive, well-designed applications isn't just a technical endeavor; it's the linchpin of your digital presence. From seamless user experiences to consistent performance across devices, every aspect of your application relies on a robust development process.",
      'But the impact of application development extends beyond mere functionality. It influences the quality of your mobile solutions, affecting factors like reliability, accessibility, and user engagement. By investing in a streamlined, agile development approach, you set the stage for delivering superior applications that exceed user expectations and drive business growth.',
      "Moreover, our focus on fast development ensures you can swiftly bring your vision to life without compromising quality or efficiency. Whether you're targeting iOS or Android platforms—or both—we provide intuitive, well-designed applications that resonate with your audience and elevate your brand.",
      'Key highlights of our iOS and Android application development:',
    ],
    textList: [
      '🔧 Swift development for rapid deployment',
      '🚀 Seamless performance across devices and platforms',
      '🔒 Intuitive, well-designed interfaces for optimal user experiences',
      '🌐 Flexibility and adaptability to evolving market demands',
    ],
    endText:
      "Don't overlook the importance of robust application development in shaping your digital success. Take the initiative to propel your mobile presence to new heights. Contact us today to explore how our application development services can transform your vision into reality!",
  },
  {
    title: 'Technology Consulting',
    id: 'technology_consulting',
    img: 'https://i.ibb.co/WKbvL7X/services7.png',

    texts: [
      '💡 Unlock Your Business Potential with Tailored Technology Consulting Solutions! 🚀',
      'In the ever-evolving landscape of technology, finding the right solutions for your business can be a daunting task. Are you ready to navigate the complexities of the digital world with confidence? Our technology consulting services offer a bespoke approach to identifying and implementing the most suitable technologies for your unique business needs.',
      'At the heart of our technology consulting offerings is a commitment to understanding your business inside and out. We recognize that no two businesses are alike, which is why we take a personalized approach to every client. By gaining a deep understanding of your goals, challenges, and aspirations, we can tailor our recommendations to align perfectly with your vision.',
      'But what sets our technology consulting apart is our dedication to providing the most cutting-edge solutions available. We leverage the latest advancements in technology to uncover innovative solutions that set you apart from the competition. From AI-driven analytics to blockchain-powered platforms, we harness the power of technology to drive your business forward.',
      "Moreover, our unique approach ensures that we find the best and most unique solutions for every business. Whether you're a startup looking to disrupt the market or an established enterprise seeking to optimize operations, our technology consulting services provide the expertise and guidance you need to achieve your goals.",
      'Key highlights of our technology consulting solutions:',
    ],
    textList: [
      '🔍 Personalized approach tailored to your business needs',
      '🌟 Access to the most cutting-edge technologies',
      '🚀 Innovative solutions that set you apart from the competition',
      '🔧 Expertise and guidance to achieve your business goals',
    ],
    endText:
      "Don't let technology hold you back. Embrace the power of technology consulting and unlock new opportunities for growth and success. Contact us today to discover how our tailored solutions can transform your business trajectory!",
  },
  {
    title: 'IT Outstaffing Services',
    id: 'it_outstaffing_services',
    img: 'https://i.ibb.co/DR86Qbm/services8.png',

    texts: [
      '🤝 Scale Your Business Seamlessly with Our IT Outstaffing and Outsourcing Services! 🚀',
      'Are you ready to take your business to new heights without the hassle of managing an in-house software team? Our IT outstaffing and outsourcing services offer a strategic solution to scale your business while minimizing overhead and maximizing efficiency.',
      "At the heart of our outstaffing and outsourcing solutions is the ability to scale your business effortlessly. Whether you're experiencing rapid growth or need to tackle a new project, we've got you covered. With our flexible staffing model, you can ramp up or down as needed without the logistical challenges of hiring and managing additional staff.",
      "But the benefits don't stop there. We take care of all aspects of software development, from creating custom solutions to integrating with existing processes. Our team of skilled professionals works seamlessly with your existing team, providing the expertise and support you need to achieve your business goals.",
      'Moreover, our fast hiring process ensures that you can onboard top talent quickly and efficiently, saving you valuable time and resources. With significant cost savings compared to traditional hiring models, our outstaffing and outsourcing services offer a cost-effective solution to meet your software needs.',
      'Key highlights of our IT outstaffing and outsourcing services:',
    ],
    textList: [
      '✨ Seamless scalability to accommodate your business growth',
      '🔧 Expertise across all aspects of software development',
      '🚀 Fast hiring process with significant cost savings',
      '🔒 Peace of mind knowing that your software needs are in good hands',
    ],
    endText:
      "Don't let staffing challenges hold you back. Embrace the power of IT outstaffing and outsourcing and unlock new opportunities for growth and success. Contact us today to discover how our tailored solutions can propel your business forward!",
  },
  {
    title: 'Custom Software Solutions',
    id: 'custom_software_solutions',
    img: 'https://i.ibb.co/QcF9d5g/services9.png',

    texts: [
      '🛠️ Unleash Your Business Potential with Custom Software Development Solutions! 🚀',
      'Are you ready to transcend the limitations of off-the-shelf software and embrace a solution tailored specifically to your unique business needs? Look no further than our custom software development services. We specialize in crafting bespoke IT solutions that go beyond standard web development, catering to a diverse array of needs across various industries.',
      "At the core of our custom software development offerings is a commitment to innovation and versatility. We understand that every business is unique, with its own set of challenges and opportunities. That's why we take a comprehensive approach, offering not only web development solutions but also a wide range of IT solutions to address all aspects of your business operations.",
      "Whether you're looking to streamline business processes, optimize inventory management, or enhance customer engagement, we've got you covered. Our expertise extends to:",
      `
    🔹 Business automation solutions to boost efficiency and productivity
    🔹 Custom programs for product management and sales optimization
    🔹 ERP systems for comprehensive control over all aspects of your business
    🔹 Custom computational programs to support scientific research and analysis
    🔹 Tailored applications for both mobile and desktop platforms
    🔹 Any other custom IT solution to meet your specific requirements    
    `,
      'With our custom software development services, the possibilities are endless. Our team of experienced developers works closely with you to understand your unique needs and objectives, ensuring that the solutions we deliver not only meet but exceed your expectations.',
      'Key highlights of our custom software development solutions:',
    ],
    textList: [
      '🔍 Comprehensive approach addressing diverse business needs',
      '🌟 Tailored solutions designed to optimize efficiency and performance',
      '🚀 Expertise across various industries and applications',
      '🔧 End-to-end support from concept to implementation and beyond',
    ],
    endText:
      "Don't settle for one-size-fits-all solutions. Unlock the full potential of your business with our custom software development services. Contact us today to embark on a journey of innovation and growth!",
  },
];
