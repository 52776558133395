import { images } from '../../configs';
import AboutOurWorkItem from './AboutOurWorkItem';
import './aboutOurWork.scss';

const AboutOurWork = () => {
  const {aboutourwork1,aboutourwork2,aboutourwork3,aboutourwork4} = images
  const aboutOurWorkItems = [
    {image: aboutourwork1, title: 'Dedication To Quality', text: "At AVWsoft, quality isn't just a priority; it's a cornerstone of our ethos, guiding every aspect of our commitment to delivering exceptional services and solutions to our valued clients."},
    {image: aboutourwork2, title: 'Maintenance And Support', text: "At AVWsoft, we not only provide quality services but also offer ongoing maintenance and support to ensure that our solutions continually meet evolving business needs and proactively tackle any potential challenges that may arise."},
    {image: aboutourwork3, title: 'Integrity And Honesty', text: "At AVWsoft, integrity and honesty are at the core of our operations, and we uphold the highest standards of transparency and ethical business practices in all our endeavors."},
    {image: aboutourwork4, title: 'Innovative Solutions', text: "In AVWsoft, innovation is at the forefront of our approach, driving us to continuously pioneer new and creative solutions that push the boundaries of what's possible in the digital realm."},
  ]

  return (
    <section id='about_our_work' className='about_our_work_section'>
      <h2>Cornerstones of our work</h2>
      <div className='about_our_work_content'>
        {aboutOurWorkItems.map((item) => {
          return (
            <AboutOurWorkItem key={item.image} image={item.image} text={item.text} title={item.title}/>
          )
        })}
      </div>
    </section>
  );
};

export default AboutOurWork;
