import { useNavigate } from 'react-router-dom';
import './footer.scss';
import { images } from '../../configs';
import { FC } from 'react';

const Footer:FC<{getElementId:any}> = ({getElementId}) => {
  const navigate = useNavigate()
  const {logo, emaillogo, phonelogo, instalogo, copyrightlogo} = images

  const copyEmail = () => {
    navigator.clipboard.writeText('info@avwsoft.com')
  }
  const logoClick = () => {
    navigate('/'); 
    getElementId('carousel_section')
  }

  return (
    <footer className='footer'>
      <div className='footer_social'>
        <img className='header_logo' src={logo} alt='logo' width={55} height={55} onClick={logoClick} />
        <div>
          <img src={emaillogo} alt='' />
          <h1 title='Click to copy' onClick={copyEmail}>info@avwsoft.com</h1>
        </div>
        {/* <div>
          <img src={phonelogo} alt='' />
          <h1>+37455536752</h1>
        </div> */}
        <a href='https://www.instagram.com/avwsoft.com_?igsh=N2c1NzBvNzF3MTJr' target='blank'>
          <img src={instalogo} alt='' />
          <h1>avwsoft.com</h1>
        </a>
      </div>
      <hr />
      <div className='footer_copyright'>
        <img src={copyrightlogo} alt='' />
        <p>Copyright - 2024 All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
