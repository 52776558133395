import { FC } from "react"

const OurProcessItem:FC<{order: string,title: string, icon: string, textItems: {item: string}[]}> = ({order, title, icon, textItems}) => {
  return (
    <div className="our_process_item">
      <div className="our_process_item_heading">
        <p>{order}</p>
        <img src={icon} alt="" />
      </div>
      <div className="our_process_item_content">
        <h3>{title}</h3>
        <ul>
          {textItems.map(item => {
            return (
              <li key={item.item}>{item.item}</li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default OurProcessItem
