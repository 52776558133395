// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { FC } from 'react';
// const ScrollToElement:FC<{elementId: string}> = ({ elementId }) => {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     const element = document.getElementById(elementId);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [pathname, elementId]);

//   return null;
// };

// export default ScrollToElement;



import { FC } from 'react';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToElement:FC<{elementId:any}> = ({ elementId}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementPosition - 80,
        behavior: 'smooth'
      });
    }
  }, [pathname, elementId]);

  return null;
};

export default ScrollToElement;