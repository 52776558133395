import { useState } from 'react'
import './carousel.scss'
import { images } from '../../../configs'


const Carousel = () => {
  const {bgImage1,bgImage2,bgImage3,bgImage4} = images
const [carouselImages, setCarouselImages] = useState([bgImage3,bgImage2,bgImage1,bgImage4])
  

  return (
    <div id='carousel_section' className='carousel_container'>
      {/* <div className='carousel_item'>
        <img src={carouselImages[0]} alt="" />
      </div> */}
      {carouselImages.map(item => {
        return (
          <div key={item} className='carousel_item'>
            <img src={item} alt="" />
          </div>
        )
      })}
    </div>
  )
}

export default Carousel
