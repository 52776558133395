import axios from 'axios'
import './getInTouch.scss'
import { images, countries } from '../../configs'
import { useEffect, useState } from 'react'
import RequestModal from '../RequestModal'

const GetInTouchSection = () => {
  const [countryCode, setCountryCode] = useState('US')
  const [loading, setLoading] = useState(false)
  const [successfulRequest, setSuccessfulRequest] = useState(false)
  const [unSuccessfulRequest, setUnSuccessfulRequest] = useState(false)
  const [emailNotValid, setEmailNotValid] = useState(false)
  const [phoneNotValid, setPhoneNotValid] = useState(false)
  const [formState, setFormState] = useState<{fullname: string, email: string, phone: string, phoneCode: string, message: string, locationApi: any}>({fullname: '', email: '', phone: '', phoneCode: '', message: '', locationApi: {}})
  
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^[0-9]+$/;

  // const emailApi = process.env.REACT_APP_EMAIL_API
  // const locationApi = process.env.REACT_APP_LOCATION_API
  const emailApi = '/'
  const locationApi = 'https://ipapi.co/json/'
  const mountData = (res: any) => {
    setCountryCode(res.data.country_code); 
    setFormState(state => {
      return {
        ...state,
        locationApi: res.data
      }
    })
  }
  useEffect(() => {
    if(locationApi) {
    axios.get(locationApi).then(res => {mountData(res)}).catch(error => console.log(error))
    }
  },[])  
  
  useEffect(() => {
    if(countryCode) {
      const country = countries.filter((item) => {
        if(countryCode === item.code) {
          return countryCode
        }
      })
      setFormState(state => {
        return {
          ...state,
          phoneCode: country[0].phone
        }
      })
    }
  }, [countryCode])

  const sendRequest = () => {
    if((!formState.email || !emailRegex.test(formState.email)) && (!formState.phone || !phoneRegex.test(formState.phone))) {
      setPhoneNotValid(true)
      setEmailNotValid(true)
    } else if(!formState.phone || !phoneRegex.test(formState.phone)) {      
      setPhoneNotValid(true)
    } else if(!formState.email || !emailRegex.test(formState.email)) {
      setEmailNotValid(true)
    } else {
      setEmailNotValid(false)
      setPhoneNotValid(false)
      setLoading(true)
      if(emailApi) {
        axios.post(emailApi, formState, { withCredentials: true }).then(res => {
          setLoading(false)
          setUnSuccessfulRequest(false)
          setSuccessfulRequest(true)
          setFormState({fullname: '', email: '', phone: '', phoneCode: '', message: '', locationApi: {}})
        }).catch(e => {
          setSuccessfulRequest(false)
          setUnSuccessfulRequest(true)
        })
      }
    
    }
    //stuc sharunakel
    
  }
  

  const exitModal = () => {
    setUnSuccessfulRequest(false)
    setSuccessfulRequest(false)
    setLoading(false)
  }

  const selectHandler = (value: any) => {
    setFormState((state) => {
      return {
        ...state,
        phoneCode: value
      }
    })
  }

  const fullnameHandler = (e: any) => {
    setFormState(state => {
      return {
        ...state,
        fullname: e.target.value
      }
    })
  }
  const emailHandler = (e: any) => {
    if(emailNotValid) {
     setEmailNotValid(false)
    }
    setFormState(state => {
      return {
        ...state,
        email: e.target.value
      }
    })
  }
  const phoneHandler = (e: any) => {
    if(phoneNotValid) {
      setPhoneNotValid(false)
    }
    setFormState(state => {
      return {
        ...state,
        phone: e.target.value
      }
    })
  }
  const messageHandler = (e: any) => {
    setFormState(state => {
      return {
        ...state,
        message: e.target.value
      }
    })
  }
  
  const {map} = images
  return (
    <section id='get_in_touch' className='get_in_touch_section'>
      {loading && <RequestModal exitModal={exitModal} modalState='loading'/>}
      {successfulRequest && <RequestModal exitModal={exitModal} modalState='successful'/>}
      {unSuccessfulRequest && <RequestModal exitModal={exitModal} modalState='unsuccessful'/>}

      <div className='get_in_touch_heading'>
        <h3>Technology Without Boundaries</h3>
        <h5>Our services are available all over the world</h5>
        <img src={map} alt="" />
      </div>
      <div id='getInTouch' className='get_in_touch_section_content'>
        <h2>Get In Touch</h2>
        <h3>Let’s make something awesome together!</h3>
        <div className='get_in_touch_forms'>
          <div className='form_item'>
            <input value={formState.fullname} onChange={fullnameHandler} type="text" id='fullname' name='fullname' placeholder='Enter Your full name'/>
          </div>
          <div className="form_item">
          {emailNotValid && <p style={{color: 'red', textAlign: 'start', fontSize: '16px'}}>NOT VALID</p>}
            <input value={formState.email} onChange={emailHandler} type="text" id='email' className={`${emailNotValid ? 'input_error' : ''}`} name='email' placeholder='Enter email'/>
          </div>

          <div className='form_item' id=''>
          {phoneNotValid && <p style={{color: 'red', textAlign: 'start', fontSize: '16px'}}>NOT VALID</p>}

          <div className="" id='form_item_phone'>
            <select id='phone_options' onChange={e => selectHandler(e.target.value)}>
              {countries.map(item => {
                if(item.code === countryCode) {
                  return <option key={`${item.flag}${item.phone}`} value={`${item.flag}${item.phone}`} selected>{`${item.flag}${item.phone}`}</option>  
                } else {
                  return <option key={`${item.flag}${item.phone}`} value={`${item.flag}${item.phone}`}>{`${item.flag}${item.phone}`}</option>
                }
              })}
            </select>
            
            <input value={formState.phone} onChange={phoneHandler} type="text" id='phone' className={`${phoneNotValid ? 'input_error' : ''}`} name='phone'/>
          </div>
          </div>
          <div className="form_item">
            <textarea value={formState.message} onChange={messageHandler} id='message' name='message' placeholder='How can we help you?'/>
          </div>
          <button onClick={sendRequest}>Send</button>
        </div>
      </div>
    </section>
  )
}

export default GetInTouchSection
